<template>
  <div class="main">
    <div class="main__background" id="top">
        <img class="main__background--img" src="../assets/textures/amarillo.png" alt="paint" />
    </div>
    <div class="main__header">
      <h2 class="main__header--title">TU CORREO HA SIDO ENVIADO</h2>
      <p class="main__header--subtitle">ENSEGUIDA TE RESPONDEREMOS</p>
    </div>
    <div class="main__item">
      <router-link to="/pinturas">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../assets/images/Fotografía/Gastronomía/105.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                FOTOGRAFÍAS
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
      <router-link to="/fotografias">
        <categories>
          <template v-slot:img>
            <img class="main__categories--img" src="../assets/images/Pinturas/5.jpg" alt="">
          </template>
          <template v-slot:text>
            <btn-prim>
              <template v-slot:text>
                PINTURAS
              </template>
            </btn-prim>
          </template>
        </categories>
      </router-link>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import BtnPrim from '../components/Buttons/BtnPrim.vue'
import Categories from '../components/Categories.vue'
import Footer from '../components/Footer.vue';
export default {
  data() {
    return {

    }
  },
  components: {
    BtnPrim,
    Categories,
    Footer
  }
}
</script>

<style scoped>
.main__background {
  position: relative;
}
.main__background--img {
  width: 100%;
  height: 570px;
  object-fit: cover;
}
.main__header {
  width: 100%;
  position: absolute;
  color: var(--txt-color);
  text-align: center;
  top: 250px;
  margin: auto;
}
.main__header--title{
  font-family: 'Yeseva One';
  font-size: 80px;
  padding: 9px 0px;
  margin: 2px;
}
.main__header--subtitle{
  font-family: var(--font);
  font-weight: 500;
  padding: 9px 0px;
  line-height: 28px;
  margin: 2px;
}
.main__item{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 0px 50px;
  margin-top: -100px;
}
.main__categories--img{
  width: 500px;
  height: 500px;
  object-fit: none;
}
@media (max-width: 800px){
  .main__header--title{
    font-size: 50px;
  }
  .main__categories--img {
    width: 200px;
    height: 200px;
  }
}
</style>