<template>
  <div class="main">
      <div class="main__background">
        <slot name=img class="main__background--img"></slot>
      </div>
      <div class="main__btn">
        <slot name="text"></slot>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.main{
    display: block;
    margin: 0px 70px 80px 70px;
    text-align: center;
}
.main__background {
    position: relative;
}
.main__btn{
    margin: 10px;
}
</style>